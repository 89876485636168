import React, { useState, useEffect } from "react";
import ReactMapboxGl, { Layer, Feature, ZoomControl } from "react-mapbox-gl";
import "mapbox-gl/src/css/mapbox-gl.css";

const PropertyMap = ({ gps_coordinates }) => {
  const [hasMounted, setHasMounted] = useState(false);
  useEffect(() => {
    setHasMounted(true);
  }, []);
  if (!hasMounted) {
    return null;
  }

  const Map = ReactMapboxGl({
    accessToken: process.env.GATSBY_MAPBOX_TOKEN,
    minZoom: 6,
    maxZoom: 12,
    navigationControl: true,
    scrollZoom: true,
    interactive: false,
  });

  const [lat, lon] = gps_coordinates.split(", ");
  return (
    <Map
      style="mapbox://styles/freiksenet/ck7ejv8wk2ei81iob3yexuywh"
      containerStyle={{
        height: "500px",
        width: "100%",
      }}
      center={[lon, lat]}
      zoom={[9.5]}
    >
      <ZoomControl position="top-left" />
      <Layer
        type="symbol"
        id="marker"
        layout={{
          "icon-image": "starmap50",
          "icon-size": 1,
        }}
      >
        <Feature coordinates={[lon, lat]} />
      </Layer>
    </Map>
  );
};

export default PropertyMap;
