import React from "react";
import Select from "react-select";

const makeGuestsLabels = pax => {
  return Array.from(Array(pax)).map((_, i) => ({
    value: i + 1,
    label: i + 1 === 1 ? "1 Aikuinen" : `${i + 1} Aikuista`,
  }));
};

const makeChildrenLabels = pax => {
  return Array.from(Array(pax + 1)).map((_, i) => ({
    value: i,
    label: i === 0 ? "Ei Lapsia" : i === 1 ? "1 Lapsi" : `${i} Lasta`,
  }));
};

export const GuestsSelect = ({ property, ...props }) => {
  return (
    <Select
      classNamePrefix="guests-select"
      placeholder="Aikuisia *"
      name="guests"
      isSearchable={false}
      options={makeGuestsLabels(
        property.indoorfeatures.sleeping_places +
          property.indoorfeatures.extra_beds
      )}
      {...props}
    />
  );
};

export const ChildrenSelect = ({ property, ...props }) => {
  return (
    <Select
      classNamePrefix="children-select"
      placeholder="Lapsia (alle 3-vuotiaat)"
      name="children"
      isSearchable={false}
      options={makeChildrenLabels(
        property.indoorfeatures.sleeping_places +
          property.indoorfeatures.extra_beds
      )}
      {...props}
    />
  );
};
