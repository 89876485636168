// https://github.com/JoinColony/use-before-unload/blob/master/src/index.ts

import { useEffect } from "react";
// import { globalHistory } from "@reach/router";

const useBeforeUnload = value => {
  const handleBeforeunload = evt => {
    let returnValue;
    if (typeof value === "function") {
      returnValue = value(evt);
    } else {
      returnValue = value;
    }
    if (returnValue) {
      evt.preventDefault();
      evt.returnValue = returnValue;
    }
    return returnValue;
  };

  const handleBeforeunloadGatsby = evt => {
    let returnValue;
    if (typeof value === "function") {
      returnValue = value(evt);
    } else {
      returnValue = value;
    }
    if (returnValue) {
      const ok = window.confirm(returnValue);
      if (!ok) {
        evt.preventDefault();
      }
      window.removeEventListener("beforeunload", handleBeforeunload);
    }
  };

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeunload);
    document.querySelectorAll("a:link").forEach(e => {
      e.addEventListener("click", handleBeforeunloadGatsby);
    });
    return () => {
      window.removeEventListener("beforeunload", handleBeforeunload);
      document.querySelectorAll("a:link").forEach(e => {
        e.removeEventListener("click", handleBeforeunloadGatsby);
      });
    };
  }, []);
};

export default useBeforeUnload;
