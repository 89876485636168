import React from "react";
import Layout from "../layout";
import PropertyDescription from "./property-description";
import PropertyGallery from "./property-gallery";
import PropertyFormTabs from "./property-form-tabs";
import SwiperCards from "../indexpage/swipercards";
import BookingFooter from "./booking-footer";
import Section from "../section";
// import PropertyReviews from "../components/property-page/property-reviews";
import PropertyMap from "./property-map";

const PropertyContent = ({
  property,
  gaiaPage,
  onStartReservation,
  availability,
}) => {
  return (
    <>
      <Layout>
        <PropertyGallery gallery={gaiaPage.gallery || []}></PropertyGallery>
        <Section>
          <div className="row">
            <div className="col-xl-8">
              <PropertyDescription gaiaPage={gaiaPage} property={property} />
              {/* <section className="py-4"> */}
              <div className="text-block">
                <h4 className="text-primary mb-4">Kohteen sijainti</h4>
                <PropertyMap gps_coordinates={property.gps_coordinates} />
              </div>
              {/* </section> */}

              {/* <PropertyReviews /> */}
            </div>
            <div className="col-xl-4">
              <div
                style={{ top: 100, minHeight: "30rem" }}
                className="booking-side-form p-4 shadow ml-lg-4 rounded sticky-top"
              >
                <PropertyFormTabs
                  property={property}
                  gaiaPage={gaiaPage}
                  availability={availability}
                  onStartReservation={onStartReservation}
                />
              </div>
            </div>
          </div>
          <div className="text-block">
            <SwiperCards
              cards={gaiaPage.related_pages}
              type="property-card"
              small
              // title="Related properties"
              // subtitle={`Similar to ${property.marketing_name}`}
              subtitle="Myös nämä saattavat kiinnostaa sinua"
            />
          </div>
        </Section>
      </Layout>
      <BookingFooter
        property={property}
        gaiaPage={gaiaPage}
        availability={availability}
        onStartReservation={onStartReservation}
      />
    </>
  );
};

export default PropertyContent;
