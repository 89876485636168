import React, { useState } from "react";
import { Link } from "gatsby";

import { Image } from "../../../image";

import { GuestsSelect, ChildrenSelect } from "../property-booking-form-selects";
import EditableBookingInfo from "../booking/editable-booking-info";
import BookingTermsAndConditions from "./booking-terms-and-conditions";

import { format, differenceInCalendarDays } from "date-fns";
import { fi } from "date-fns/locale";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faUsers,
  faChild,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import BookingCalendar from "./booking-calendar";

const BookingDetails = ({
  property,
  booking,
  gaiaPage,
  availability,
  isLoading,
  onConfirmReservation,
}) => {
  const [bookingFormState, setBookingFormState] = useState({
    calendar: booking.calendar,
    guests: booking.guests,
    children: booking.children
      ? booking.children
      : {
          value: "",
          label: "Ei Lapsia",
        },
    message: "",
    name: "",
    email: "",
    phone: "",
    suite: booking.suite,
    bookingConditions: false,
  });
  const [bookingConditionsExpanded, setBookingConditionsExpanded] = useState(
    false
  );
  let bookingConditionsText;
  if (bookingConditionsExpanded) {
    bookingConditionsText = (
      <BookingTermsAndConditions availability={availability} />
    );
  }

  const handleInputChange = (name, value) => {
    setBookingFormState({
      ...bookingFormState,
      [name]: value,
    });
  };

  let buttonText = "VARAA NYT";
  if (isLoading) {
    buttonText = (
      <FontAwesomeIcon
        icon={faSpinner}
        pulse
        className="text-white turn-icon"
        fixedWidth
        size="lg"
      />
    );
  }

  const startDate = bookingFormState.calendar[0];
  const endDate = bookingFormState.calendar[1];

  let calendarStartBlock = (
    <EditableBookingInfo
      renderLabel={startEditing => (
        <>
          <a
            className="text-decoration-none"
            type="button"
            onClick={() => {
              startEditing();
            }}
          >
            <div className="date-tile mr-3" style={{ position: "relative" }}>
              <FontAwesomeIcon icon={faPen} className="edit-button" size="xs" />
              <div className="text-uppercase">
                <span className="text-sm">
                  {format(startDate, "MMM", { locale: fi })}
                </span>
                <br />
                <strong className="text-lg">
                  {format(startDate, "d", { locale: fi })}
                </strong>
              </div>
            </div>
          </a>
        </>
      )}
      renderSelect={(ref, stopEditing) => (
        <div ref={ref}>
          <a
            className="text-decoration-none"
            onClick={() => {
              stopEditing();
            }}
          >
            <div className="date-tile mr-3" style={{ position: "relative" }}>
              <FontAwesomeIcon icon={faPen} className="edit-button" size="xs" />
              <div className="text-uppercase">
                <span className="text-sm">
                  {format(startDate, "MMM", { locale: fi })}
                </span>
                <br />
                <strong className="text-lg">
                  {format(startDate, "d", { locale: fi })}
                </strong>
              </div>
            </div>
          </a>
          <div className="datepicker-container datepicker-container-left">
            <div className="date-picker-wrapper no-shortcuts inline-wrapper">
              <BookingCalendar
                showDoubleView={false}
                onChange={(value, event) => {
                  handleInputChange("calendar", value);
                  stopEditing();
                }}
                value={bookingFormState.calendar}
                availability={availability}
                suite={bookingFormState.suite.value}
              />
            </div>
          </div>
        </div>
      )}
    />
  );

  let calendarEndBlock = (
    <EditableBookingInfo
      renderLabel={startEditing => (
        <>
          <a
            className="text-decoration-none"
            type="button"
            onClick={() => {
              startEditing();
            }}
          >
            <div className="date-tile mr-3" style={{ position: "relative" }}>
              <FontAwesomeIcon icon={faPen} className="edit-button" size="xs" />
              <div className="text-uppercase">
                <span className="text-sm">
                  {format(endDate, "MMM", { locale: fi })}
                </span>
                <br />
                <strong className="text-lg">
                  {format(endDate, "d", { locale: fi })}
                </strong>
              </div>
            </div>
          </a>
        </>
      )}
      renderSelect={(ref, stopEditing) => (
        <div ref={ref}>
          <a
            className="text-decoration-none"
            onClick={() => {
              stopEditing();
            }}
          >
            <div className="date-tile mr-3" style={{ position: "relative" }}>
              <FontAwesomeIcon icon={faPen} className="edit-button" size="xs" />
              <div className="text-uppercase">
                <span className="text-sm">
                  {format(endDate, "MMM", { locale: fi })}
                </span>
                <br />
                <strong className="text-lg">
                  {format(endDate, "d", { locale: fi })}
                </strong>
              </div>
            </div>
          </a>
          <div className="datepicker-container datepicker-container-left">
            <div className="flex-1-1 date-picker-wrapper no-shortcuts inline-wrapper">
              <BookingCalendar
                showDoubleView={false}
                onChange={(value, event) => {
                  handleInputChange("calendar", value);
                  stopEditing();
                }}
                value={bookingFormState.calendar}
                availability={availability}
                suite={bookingFormState.suite.value}
              />
            </div>
          </div>
        </div>
      )}
    />
  );

  const theme = theme => {
    return {
      ...theme,
      colors: {
        ...theme.colors,
        // danger: "#DE350B",
        // dangerLight: "#c66",
        // neutral0: "#c66",
        // neutral5: "#c66",
        // neutral10: "#c66",
        // neutral20: "#c66",
        // neutral30: "#c66",
        // neutral40: "#c66",
        // neutral50: "#c66",
        // neutral60: "#c66",
        // neutral70: "#c66",
        neutral80: "#c66",
        // neutral90: "#c66",
        primary: "#f9eded",
        primary25: "#f9eded",
        primary50: "#f9eded",
        // primary75: "#4C9AFF",
        // text: "#c66",
      },
    };
  };

  const primaryTheme = {
    container: provided => ({
      ...provided,
      color: "#c66",
      textTransform: "uppercase",
    }),
    control: provided => ({
      ...provided,
      borderColor: "#c66",
      "&:hover": {
        borderColor: "#c66",
      },
      boxShadow: "#c66",
    }),
    option: provided => ({
      ...provided,
      color: "#c66",
      textTransform: "uppercase",
    }),
  };
  let guestsBlock = (
    <EditableBookingInfo
      renderLabel={startEditing => (
        <p className="text-sm text-primary mb-0 text-uppercase">
          {bookingFormState.guests.label}
          <a
            className="ml-2"
            type="button"
            onClick={() => {
              startEditing();
            }}
          >
            (Edit)
          </a>
        </p>
      )}
      renderSelect={(ref, stopEditing) => (
        <div ref={ref} className="flex-1-1">
          <GuestsSelect
            styles={primaryTheme}
            theme={theme}
            property={property}
            value={bookingFormState.guests}
            onChange={(value, event) => {
              handleInputChange(event.name, value);
              stopEditing();
            }}
          />
        </div>
      )}
    />
  );

  let childrenBlock = (
    <EditableBookingInfo
      renderLabel={startEditing => (
        <p className="text-sm text-primary mb-0 text-uppercase">
          {bookingFormState.children.label}
          <a
            className="ml-2"
            type="button"
            onClick={() => {
              startEditing();
            }}
          >
            (Edit)
          </a>
        </p>
      )}
      renderSelect={(ref, stopEditing) => (
        <div ref={ref} className="flex-1-1">
          <ChildrenSelect
            styles={primaryTheme}
            theme={theme}
            property={property}
            value={bookingFormState.children}
            onChange={(value, event) => {
              handleInputChange(event.name, value);
              stopEditing();
            }}
          />
        </div>
      )}
    />
  );

  const sumNights = availability
    ? availability.calculatePrice(startDate, endDate, booking.suite?.value)
    : differenceInCalendarDays(endDate, startDate) *
      (property.starting_price / 7);

  let isFormEmpty =
    bookingFormState.name === "" ||
    bookingFormState.email === "" ||
    !bookingFormState.bookingConditions;

  let suiteName;
  if (booking.suite) {
    suiteName = (
      <>{gaiaPage.suites.find(({ id }) => booking.suite.value).name}, </>
    );
  }

  return (
    <>
      <div className="bg-primary-light rounded-top p-4">
        <div className="row">
          <div className="col-md-7 order-xl-1 order-2">
            <p className="subtitle text-primary text-uppercase">
              Huvilavarauksen Tiedot
            </p>

            <h5 className="mb-4 h4">
              <a className="text-decoration-none" href={gaiaPage.url}>
                {suiteName}
                {property.marketing_name}, {property.region.name}
              </a>
            </h5>
            <div className="pt-2">
              <div className="row mb-3">
                <div className="col-md-6 d-flex align-items-center mb-3 mb-md-0">
                  {calendarStartBlock}
                  <p className="text-sm text-primary mb-0 text-uppercase">
                    Check-in
                    <br />
                    {format(startDate, "EEEEEE", { locale: fi })},{" "}
                    {property.current_season.check_in_time}
                  </p>
                </div>

                <div className="col-md-6 d-flex align-items-center">
                  {calendarEndBlock}
                  <p className="text-sm text-primary mb-0 text-uppercase">
                    Check-out
                    <br />
                    {format(endDate, "EEEEEE", { locale: fi })},{" "}
                    {property.current_season.check_out_time}
                  </p>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-6 d-flex align-items-center mb-3 mb-md-0">
                  <div className="date-tile mr-3">
                    <FontAwesomeIcon
                      icon={faUsers}
                      className="text-primary"
                      size="2x"
                    />
                  </div>
                  {guestsBlock}
                </div>
                <div className="col-md-6 d-flex align-items-center">
                  <div className="date-tile mr-3">
                    <FontAwesomeIcon
                      icon={faChild}
                      className="text-primary"
                      size="2x"
                    />
                  </div>
                  {childrenBlock}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5 order-xl-2 order-1">
            <a className="text-decoration-none" href={gaiaPage.url}>
              <Image
                layout="constrained"
                src={`/_${gaiaPage.first_picture.url}`}
                background={gaiaPage.first_picture.placeholder}
                width={855}
                height={570}
                alt={property.marketing_name}
                className="img-fluid rounded"
              />
            </a>
          </div>
        </div>
      </div>

      <div className="pt-4">
        <h5 className="mb-3">
          {differenceInCalendarDays(endDate, startDate)} vuorokautta{" "}
          {property.marketing_name} hintaan
        </h5>
        <table className="w-100 text-muted">
          <tbody>
            <tr className="bg-gray-100">
              <th className="font-weight-normal pt-2 pb-3">Vuokrahinta</th>
              <td className="text-left pt-2 pb-3 pl-2">
                <span className="font-weight-bold mr-2">
                  €{sumNights.toLocaleString("fi")}
                </span>
                {/* € */}
                {/* {property.starting_price.toLocaleString("fi")}/vko x{" "} */}
                ({differenceInCalendarDays(endDate, startDate)} vuorokautta)
              </td>
            </tr>
            <tr>
              <th className="font-weight-normal pt-2 pb-3">Hintaan sisältyy</th>
              <td className="text-left pt-2 pb-3 pl-2">
                {property.current_season.price_includes}
              </td>
            </tr>
            <tr className="bg-gray-100">
              <th className="font-weight-normal pt-2 pb-3">
                Hintaan ei sisälly
              </th>
              <td className="text-left pt-2 pb-3 pl-2">
                {property.current_season.extra_payments}
              </td>
            </tr>
            <tr>
              <th className="font-weight-normal pt-2 pb-3">Muuta</th>
              <td className="text-left pt-2 pb-3 pl-2">
                {property.current_season.optional_payments}
              </td>
            </tr>
            {gaiaPage.has_discounts && (
              <tr>
                <th className="font-weight-normal pt-2 pb-3">
                  Tarjous & Last-Minute
                </th>
                <td className="text-left pt-2 pb-3 pl-2">
                  {gaiaPage.discounts.join(", ")}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="pt-4">
        <h5 className="mb-3">Varaajan tiedot</h5>
        <div className="">
          <form>
            <div className="controls">
              <div className="form-group">
                <label htmlFor="name" className="form-label">
                  Nimi *
                </label>
                <input
                  name="name"
                  id="name"
                  placeholder=""
                  required="required"
                  className="form-control"
                  value={bookingFormState.name}
                  onChange={event => {
                    handleInputChange(event.target.name, event.target.value);
                  }}
                />
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="email" className="form-label">
                      Sähkoposti *
                    </label>
                    <input
                      name="email"
                      id="email"
                      placeholder=""
                      required="required"
                      className="form-control"
                      value={bookingFormState.email}
                      onChange={event => {
                        handleInputChange(
                          event.target.name,
                          event.target.value
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="phone" className="form-label">
                      Puhelin
                    </label>
                    <input
                      name="phone"
                      id="phone"
                      placeholder=""
                      className="form-control"
                      value={bookingFormState.phone}
                      onChange={event => {
                        handleInputChange(
                          event.target.name,
                          event.target.value
                        );
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="message" className="form-label">
                  Toiveita / huomioitavaa
                </label>
                <textarea
                  rows="4"
                  name="message"
                  id="message"
                  placeholder=""
                  className="form-control"
                  value={bookingFormState.message}
                  onChange={event => {
                    handleInputChange(event.target.name, event.target.value);
                  }}
                ></textarea>
              </div>
              <div className="form-check text-muted pb-4">
                <input
                  type="checkbox"
                  required="required"
                  className="form-check-input"
                  id="bookingConditions"
                  name="bookingConditions"
                  checked={!!bookingFormState.bookingConditions}
                  onChange={event => {
                    handleInputChange(
                      event.target.name,
                      !bookingFormState.bookingConditions
                    );
                  }}
                />
                <label className="form-check-label" htmlFor="bookingConditions">
                  Olen lukenut{" "}
                  <a
                    className="link-fat-line"
                    onClick={e => {
                      e.preventDefault();
                      setBookingConditionsExpanded(!bookingConditionsExpanded);
                    }}
                  >
                    varausehdot
                  </a>
                </label>
                {bookingConditionsText}
              </div>
              <div style={{ textAlign: "center", flexDirection: "row" }}>
                {booking.error && (
                  <div>
                    <span className="small text-primary">{booking.error}</span>
                  </div>
                )}
                <button
                  type="submit"
                  className={`btn ${
                    isFormEmpty ? "btn-outline-primary" : "btn-primary"
                  }`}
                  disabled={isFormEmpty || isLoading}
                  style={{ width: "70%" }}
                  onClick={e => {
                    e.preventDefault();
                    onConfirmReservation(bookingFormState);
                  }}
                >
                  {buttonText}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default BookingDetails;
