import React, { useState, useRef } from "react";
import useOutsideClick from "../../../hooks/useOutsideClick";

const EditableBookingInfo = ({ renderLabel, renderSelect }) => {
  const [editing, setEditing] = useState(false);
  const editingRef = useRef();
  useOutsideClick(editingRef, e => {
    if (editing) {
      setEditing(false);
    }
  });

  if (editing) {
    return renderSelect(editingRef, () => {
      setEditing(false);
    });
  } else {
    return renderLabel(() => {
      setEditing(true);
    });
  }
};
export default EditableBookingInfo;
