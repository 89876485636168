/**
 * Property gallery component on top
 */

import React from "react";
import Slider from "react-slick";

import Lightbox from "yet-another-react-lightbox";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { blurhashToCssGradientString } from "@unpic/placeholder";

import { Image } from "../../image";

function blurhashToCssGradientStringWithoutError(blurhashMaybe) {
  try {
    return blurhashToCssGradientString(blurhashMaybe);
  } catch (e) {
    return "auto";
  }
}

function AngleRight(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className="gallery-angle"
      style={{
        ...style,
        right: "-1.3rem",
      }}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faAngleRight} className="" fixedWidth size="4x" />
    </div>
  );
}

function AngleLeft(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`gallery-angle ${className}`}
      style={{
        ...style,
        left: "-1.3rem",
      }}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faAngleLeft} className="" fixedWidth size="4x" />
    </div>
  );
}

const PropertyGallery = ({ gallery }) => {
  const images = gallery?.map(image => {
    const url = `/_${image.url}`;
    return {
      src: url,
      original: image.url,
      placeholder: image.placeholder,
      alt: "",
      width: 1920,
      // height: "auto",
    };
  });

  const [open, setOpen] = React.useState(false);

  // const lightboxParams = {
  //   settings: {
  //     autoplaySpeed: 0, // disable autoplay
  //     disablePanzoom: true,
  //     hideControlsAfter: false,
  //     overlayColor: "rgba(0,0,0,0.9)",
  //     lightboxTransitionSpeed: "0.3",
  //   },
  //   caption: {},
  //   buttons: {
  //     size: "60px",
  //     showAutoplayButton: false,
  //     showCloseButton: true,
  //     showDownloadButton: false,
  //     showFullscreenButton: false,
  //     showNextButton: true,
  //     showPrevButton: true,
  //     showThumbnailsButton: false,
  //   },
  //   thumbnails: {},
  //   progressBar: {
  //     showProgressBar: false,
  //   },
  // };

  var sliderParams = {
    accessibility: true,
    // arrows: true,
    // dots: true,
    draggable: true,
    infinite: true,
    lazyLoad: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    nextArrow: <AngleRight />,
    prevArrow: <AngleLeft />,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          // dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  return (
    <section className="pb-3" style={{ overflow: "hidden" }}>
      <Slider {...sliderParams}>
        {/* HACK FOR BROKEN JSON GALLERIES CHECK LATER */}
        {gallery?.map((image, i) => {
          return (
            <a
              key={i}
              style={{ margin: "10px" }}
              onClick={e => {
                e.preventDefault();
                setOpen(i);
              }}
            >
              <Image
                layout="constrained"
                priority={true}
                src={`/_${image.url}`}
                background={image.placeholder}
                width={855}
                height={570}
                alt=""
                className="img-fluid"
              />
            </a>
          );
        })}
      </Slider>
      <Lightbox
        plugins={[Thumbnails]}
        render={{
          thumbnail: props => {
            return (
              <Image
                layout="constrained"
                key={props.slide.key}
                src={props.slide.src}
                background={props.slide.placeholder}
                alt={props.slide.alt}
                width={80}
                height={60}
              />
            );
          },
          slide: props => {
            return (
              <img
                // layout="fullWidth"
                class="gallery__fullscreen-image"
                fetchpriority="high"
                key={props.slide.key}
                src={props.slide.original}
                style={{
                  background: blurhashToCssGradientStringWithoutError(
                    props.background
                  ),
                }}
                alt={props.slide.alt}
                // width={2048}
              />
            );
          },
        }}
        animation={{
          swipe: 0,
          navigation: 0,
          easing: {
            fade: "ease",
            navigation: "ease",
            swipe: "ease",
          },
        }}
        carousel={{
          padding: 0,
          spacing: 0,
          preload: 1,
        }}
        thumbnails={{
          border: 0,
          gap: 0,
          padding: 0,
          imageFit: "cover",
          width: 80,
          height: 60,
          vignette: false,
        }}
        slides={images}
        open={open}
        close={() => setOpen(false)}
      />
    </section>
  );
};
export default PropertyGallery;
