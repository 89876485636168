import React from "react";

const BookingTermsAndConditions = ({ availability }) => (
  <div
    className="terms-and-conditions mt-3"
    dangerouslySetInnerHTML={{ __html: availability.termsAndConditions }}
  />
);

export default BookingTermsAndConditions;
