import React from "react";
import { Link } from "gatsby";
import { Image } from "../../../image";

import Layout from "../../layout";
import SEO from "../../seo";

const BookingConfirmation = ({ property, booking, gaiaPage }) => {
  return (
    <>
      <SEO title={`${property.marketing_name} VARAUSVAHVISTUS`} />
      <Layout>
        <section className="hero-home dark-overlay">
          <Image
            layout="fullWidth"
            priority={true}
            width={2560}
            src={`/fit_outside${gaiaPage.first_picture.url}`}
            background={gaiaPage.first_picture.placeholder}
            alt={property.marketing_name}
            className="img-fluid bg-image blog-title-image"
          />
          <div className="container py-7">
            <div className="overlay-content text-center text-white">
              <h1 className="display-3 font-weight-bold text-shadow mb-0 blog-page-title">
                Varaustiedustelu vastaanotettu!
                <br />
                <a
                  className="text-decoration-none text-white"
                  href={gaiaPage.url}
                >
                  {property.marketing_name}, {property.region.name}
                </a>
              </h1>
            </div>
          </div>
        </section>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 mt-3">
              <div className="text-block">
                <p className="text-muted">Kiitos varauksesta.</p>
                <p className="text-muted mb-5">
                  Saat pian lisätietoja. Mukavaa loman odotusta!
                </p>
                <p className="text-center mb-5">
                  <Link to="/" className="btn btn-primary mx-2 mb-2">
                    Takaisin etusivulle
                  </Link>
                  <a
                    href={gaiaPage.url}
                    className="btn btn-outline-primary mx-2 mb-2"
                  >
                    Takaisin {property.marketing_name}:lle
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default BookingConfirmation;
