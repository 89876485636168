/**
 * Property page sidebar
 **/

import React, { useState, useRef } from "react";

import PropertyBookingForm from "./property-booking-form";
import ContactForm from "../indexpage/contactform";

const PropertyFormTabs = ({
  property,
  gaiaPage,
  mobileView,
  availability,
  onStartReservation,
}) => {
  const [activeTab, setActiveTab] = useState(
    property.integration_type ? "bookingform-tab" : "contactform-tab"
  );

  const handleTabChange = tab => {
    setActiveTab(tab);
  };

  let tabContent;
  let bookingformTabClassName = "";
  let contactformTabClassName = "";

  if (activeTab === "bookingform-tab") {
    tabContent = (
      <div
        id="bookingform"
        className="tab-pane active"
        role="tabpanel"
        // aria-labelledby="bookingform-tab"
      >
        <PropertyBookingForm
          property={property}
          gaiaPage={gaiaPage}
          mobileView={mobileView}
          availability={availability}
          onStartReservation={onStartReservation}
        />
      </div>
    );
    bookingformTabClassName = "active";
    contactformTabClassName = "text-muted";
  } else {
    tabContent = (
      <div
        id="contactform"
        className="tab-pane active"
        role="tabpanel"
        // aria-labelledby="contactform-tab"
      >
        <ContactForm property={property} mobileView={mobileView} />
      </div>
    );
    bookingformTabClassName = "text-muted";
    contactformTabClassName = "active";
  }

  let booking_tab = null;
  if (property.integration_type) {
    booking_tab = (
      <li className="nav-item">
        <a
          // id="bookingform-tab"
          className={`nav-link ${bookingformTabClassName} text-uppercase`}
          style={{
            borderTopColor: "white",
            borderLeftColor: "white",
            borderRightColor: "lightgray",
            borderRadius: "0",
          }}
          href="#bookingform"
          onClick={e => {
            e.preventDefault();
            handleTabChange("bookingform-tab");
          }}
        >
          Varaus
        </a>
      </li>
    );
  }

  return (
    <>
      <ul
        className="nav nav-tabs"
        style={{
          flexWrap: "nowrap",
          border: "0",
          paddingBottom: "10px",
          justifyContent: booking_tab ? "center" : "flex-start",
        }}
      >
        {booking_tab}
        <li className="nav-item">
          <a
            // id="contactform-tab"
            className={`nav-link ${contactformTabClassName} text-uppercase`}
            style={{
              borderBottomColor: "white",
              borderRadius: "0",
            }}
            href="#contactform"
            onClick={e => {
              e.preventDefault();
              handleTabChange("contactform-tab");
            }}
          >
            Pikatiedustelu
          </a>
        </li>
      </ul>

      <div className="tab-content">{tabContent}</div>
    </>
  );
};

export default PropertyFormTabs;
