import React from "react";

import Layout from "../../layout";
import Section from "../../section";
import SEO from "../../seo";
import BookingDetails from "./booking-details";
import useBeforeUnload from "../../../hooks/useBeforeUnload";

const Booking = ({
  property,
  booking,
  gaiaPage,
  availability,
  isLoading,
  onConfirmReservation,
}) => {
  useBeforeUnload(
    "Navigoidaan sivulta? Tekemiäsi muutoksia ei ehkä tallenneta."
  );
  return (
    <>
      <SEO title={`${property.marketing_name} VARAUS`} />
      <Layout>
        <Section padding={2} margin={2} className="min-height-without-footer">
          <div className="row">
            <div className="col-lg-12 mt-3">
              <BookingDetails
                availability={availability}
                isLoading={isLoading}
                property={property}
                booking={booking}
                gaiaPage={gaiaPage}
                onConfirmReservation={onConfirmReservation}
              />
            </div>
          </div>
        </Section>
      </Layout>
    </>
  );
};
export default Booking;
