/**
 * Mobile footer for property booking form
 **/

import React, { useState } from "react";
import PropertyFormTabs from "./property-form-tabs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const BookingFooter = ({
  property,
  gaiaPage,
  onStartReservation,
  availability,
}) => {
  const [formExpanded, setFormExpanded] = useState(false);

  let bookingForm;
  if (formExpanded) {
    bookingForm = (
      <div
        className="footer-booking-form"
        style={{
          backgroundColor: "white",
          padding: "1rem",
          position: "fixed",
          overflow: "scroll",
          top: "0",
          left: "0",
          right: "0",
          bottom: "0",
          zIndex: "1050",
        }}
      >
        <div
          className="pb-3"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <button
            className="btn"
            onClick={() => {
              setFormExpanded(!formExpanded);
            }}
          >
            <FontAwesomeIcon
              icon={faTimes}
              className="text-gray-400"
              fixedWidth
              size="2x"
            />
          </button>
        </div>
        <h2 className="text-center" style={{ paddingBottom: "5px" }}>
          {property.marketing_name}
        </h2>

        <PropertyFormTabs
          property={property}
          gaiaPage={gaiaPage}
          availability={availability}
          mobileView
          onStartReservation={onStartReservation}
        />
      </div>
    );
  } else {
    bookingForm = (
      <div className="booking-footer container-fluid sticky-bottom">
        <div
          className="row"
          style={{ backgroundColor: "#cd594a 0.9", paddingTop: "1rem" }}
        >
          <div className="col col-md-6">
            <div style={{}}>
              <p className="" style={{ color: "white" }}>
                <span className="h2">€{property.starting_price}</span>/ vko
              </p>
            </div>
          </div>
          <div className="col col-md-6">
            <div className="form-group" style={{}}>
              <button
                className="btn btn-primary btn-block"
                style={{ color: "#cd594a", backgroundColor: "white" }}
                onClick={() => {
                  setFormExpanded(!formExpanded);
                }}
              >
                Varaus
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return bookingForm;
};

export default BookingFooter;
