import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan } from "@fortawesome/free-solid-svg-icons";

const PropertyFeature = ({ textClassName, icon, label, size, isOff }) => {
  if (icon) {
    if (!isOff) {
      return (
        <>
          <FontAwesomeIcon
            icon={icon}
            className="mr-2"
            fixedWidth
            size={size}
          />
          <span className={`text-sm ${textClassName}`}>{label}</span>
        </>
      );
    } else {
      return (
        <>
          <span
            className={`fa-layers fa-fw ${size === "lg" ? "mr-3" : "mr-2"}`}
          >
            <FontAwesomeIcon
              icon={faBan}
              fixedWidth
              transform="grow-4"
              size={size}
            />
            <FontAwesomeIcon
              icon={icon}
              transform="shrink-6"
              fixedWidth
              size={size}
            />
          </span>
          <span className={`text-sm ${textClassName}`}>{label}</span>
        </>
      );
    }
  } else {
    return <span className={`text-sm ${textClassName}`}>{label}</span>;
  }
};

export default PropertyFeature;
