import React, { useState, useCallback } from "react";
import {
  differenceInCalendarDays,
  getISODay,
  isBefore,
  min,
  max,
  isSameDay,
} from "date-fns";
import Calendar from "react-calendar";

const BookingCalendar = ({
  value,
  showDoubleView,
  availability,
  suite,
  onChange,
}) => {
  const [startRange, setStartRange] = useState(null);

  const tileDisabled = useCallback(
    ({ date, view }) => {
      if (view === "month") {
        const period = availability.findPeriod(date, suite);
        if (!period) {
          return true;
        } else if (period.type === "booked") {
          return true;
        } else if (startRange) {
          const periods = availability.allPeriodsOfRange(
            startRange,
            date,
            suite
          );
          if (periods.some(({ type }) => type === "booked")) {
            return true;
          } else if (
            period.min_booking == null ||
            ["free", "ask"].includes(period.min_booking)
          ) {
            return false;
          } else if (
            Math.abs(differenceInCalendarDays(date, startRange)) <
            period.min_booking
          ) {
            return true;
          }
        } else if (period.rotation && period.rotation !== "daily") {
          if (getISODay(date) !== rotationToNumber[period.rotation]) {
            return true;
          }
        }
      }
    },
    [availability, startRange, suite]
  );

  const tileContent = useCallback(
    ({ date, view }) => {
      if (view === "month") {
        if (isBefore(date, new Date())) {
          return null;
        } else {
          const period = availability.findPeriod(date, suite);
          if (!period || period.type === "booked") {
            return null;
          } else if (startRange) {
            if (isSameDay(startRange, date)) {
              return null;
            }
            // else if (
            //   period.min_booking != null &&
            //   !["free", "ask"].includes(period.min_booking) &&
            //   Math.abs(differenceInCalendarDays(date, startRange)) <
            //     period.min_booking
            // ) {
            //   return null;
            // }
            const periods = availability.allPeriodsOfRange(
              startRange,
              date,
              suite
            );
            if (periods.some(({ type }) => type === "booked")) {
              return null;
            } else {
              const price = availability.calculatePrice(
                min([startRange, date]),
                max([startRange, date]),
                suite
              );
              let textPrice;
              if (price.indexOf('.') !== -1) {
                textPrice = price.slice(0, price.length - 3);
              } else {
                textPrice = price;
              }
              return <CalendarPrice price={textPrice} />;
            }
          } else {
            return <CalendarPrice price={(period.price / 7).toFixed(0)} />;
          }
        }
      }
    },
    [availability, startRange, suite]
  );

  return (
    <Calendar
      calendarType="ISO 8601"
      className="month-wrapper calendar-nav"
      locale="fi-FI"
      minDate={new Date()}
      minDetail="year"
      next2Label={null}
      prev2Label={null}
      returnValue="range"
      selectRange={true}
      showFixedNumberOfWeeks={true}
      showWeekNumbers={true}
      tileDisabled={tileDisabled}
      tileContent={tileContent}
      allowPartialRange={true}
      showDoubleView={showDoubleView}
      value={value}
      onChange={value => {
        if (value.length === 1) {
          setStartRange(value[0]);
        } else {
          onChange(value);
        }
      }}
    />
  );
};

export default BookingCalendar;

const CalendarPrice = ({ price }) => (
  <>
    <br />
    <div className="text-xs text-muted mt-1">€{price}</div>
  </>
);

const rotationToNumber = {
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
  sunday: 7,
};
