/**
 * Property description component
 */

import React, { useState } from "react";
import PropertyFeatureList from "./property-feature-list";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

const PropertyDescription = ({ property, gaiaPage }) => {
  const [indoorFeaturesExpanded, setIndoorFeaturesExpanded] = useState(false);

  let moreIndoorFeatures;
  if (indoorFeaturesExpanded) {
    moreIndoorFeatures = (
      <>
        <a
          className="border rounded text-uppercase text-muted text-weight-normal w-100 d-flex justify-content-center py-2 mb-2"
          onClick={() => {
            setIndoorFeaturesExpanded(!indoorFeaturesExpanded);
          }}
        >
          SULJE
        </a>
        <div className="row">
          <PropertyFeatureList property={property} type="moreFeatures" />
        </div>
      </>
    );
  } else {
    moreIndoorFeatures = (
      <a
        className="border rounded text-uppercase text-muted text-weight-normal w-100 d-flex justify-content-center py-2"
        onClick={() => {
          setIndoorFeaturesExpanded(!indoorFeaturesExpanded);
        }}
      >
        LISÄÄ
      </a>
    );
  }

  let discounts;
  if (gaiaPage.has_discounts) {
    discounts = (
      <div className="text-block">
        <h5>Tarjoukset & Last-Minute</h5>
        <ul className="p-0 list-style-position-inside text-muted font-weight-light">
          {gaiaPage.discounts.map((offer, i) => (
            <li key={i}>{offer}</li>
          ))}
        </ul>
      </div>
    );
  }

  return (
    <>
      <div className="text-block">
        <p className="text-primary">
          <FontAwesomeIcon
            icon={faMapMarkerAlt}
            className=" mr-1"
            fixedWidth
            size="sm"
          />
          {property.municipality.province.name}, {property.region.name}
        </p>
        <h1>{property.marketing_name}</h1>

        <div className="row my-3">
          <PropertyFeatureList property={property} type="generalFeatures" />
        </div>

        <div
          className="text-muted font-weight-light property-description"
          dangerouslySetInnerHTML={{
            __html: property.description_long_html,
          }}
        ></div>
      </div>
      {discounts}
      <div className="text-block">
        <h5>{property.marketing_name} lyhyesti</h5>
        <ul
          className="text-muted font-weight-light list-style-position-inside p-0"
          dangerouslySetInnerHTML={{
            __html: property.description_short_html,
          }}
        ></ul>
      </div>
      <div className="text-block">
        <h5 className="text-primary mb-4">Varustelu</h5>
        <div className="row">
          <PropertyFeatureList property={property} type="topIndoorFeatures" />
        </div>
        {moreIndoorFeatures}
      </div>
    </>
  );
};
export default PropertyDescription;
