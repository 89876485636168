import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";

import SEO from "../components/seo";
import PropertyContent from "../components/property-page/property-content";
import Booking from "../components/property-page/booking/booking";
import BookingConfirmation from "../components/property-page/booking/booking-confirmation";

import {
  differenceInCalendarDays,
  parseISO,
  toDate,
  format,
  min,
} from "date-fns";

const Property = ({ data, location }) => {
  const property = data.gaiaPage.property;
  const [lat, lon] = property.gps_coordinates.split(", ");

  const [availability, setAvailability] = useState(
    property.integration_type
      ? {
          isLoading: true,
          bookingRanges: null,
        }
      : null
  );

  useEffect(() => {
    if (property.integration_type) {
      const fetchAvailability = async () => {
        const response = await fetch(
          `https://www.gaia-travels.com/ajax/pages/availability/${
            property.id
          }/${new Date().getFullYear()}/`
        );
        const result = await response.json();
        setAvailability(new Availability(result));
      };
      fetchAvailability();
    }
  }, [property.integration_type]);

  const [bookingState, setBookingState] = useState({
    calendar: null,
    guests: null,
    children: null,
  });

  const [stage, setStage] = useState("property");

  if (
    bookingState.calendar == null &&
    (stage === "booking" || stage === "confirmation")
  ) {
    setStage("property");
    // navigate(data.gaiaPage.url, { replace: true });
    return null;
  }
  const handleStartReservation = bookingData => {
    setBookingState(bookingData);
    setStage("booking");
  };
  const handleConfirmReservation = async bookingData => {
    setBookingState(bookingData);
    setStage("confirmation-loading");
    const request = await fetch(
      "https://www.gaia-travels.com/ajax/pages/reserve/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: bookingData.name,
          email: bookingData.email,
          phone: bookingData.phone,
          message: bookingData.message,
          adults: bookingData.guests.value,
          children: bookingData.children.value,
          from_date: format(bookingData.calendar[0], "yyyy-MM-dd"),
          to_date: format(bookingData.calendar[1], "yyyy-MM-dd"),
          price: availability.calculatePrice(
            ...bookingData.calendar,
            bookingData.suite?.value
          ),
          property_id: property.id,
          suite: bookingData.suite?.value,
        }),
      }
    );
    const result = await request.json();
    if (result.ok) {
      setStage("confirmation");
    } else {
      setBookingState({
        ...bookingData,
        error: result.errors,
      });
      setStage("booking");
    }
  };

  let route = null;
  if (stage === "booking" || stage === "confirmation-loading") {
    route = (
      <Booking
        // path="/booking"
        property={property}
        availability={availability}
        booking={bookingState}
        gaiaPage={data.gaiaPage}
        onConfirmReservation={handleConfirmReservation}
        isLoading={stage === "confirmation-loading"}
      />
    );
  } else if (stage === "confirmation") {
    route = (
      <BookingConfirmation
        // path="/confirmation"
        property={property}
        booking={bookingState}
        gaiaPage={data.gaiaPage}
      />
    );
  } else {
    route = (
      <PropertyContent
        // path="/"
        property={property}
        availability={availability}
        gaiaPage={data.gaiaPage}
        onStartReservation={handleStartReservation}
      />
    );
  }
  return (
    <>
      <SEO
        // description={property.description}
        title={`${property.region.name} - ${property.municipality.province.name} - ${property.marketing_name}`}
        meta={[
          {
            property: "og:title",
            content: property.marketing_name,
          },
          {
            property: "og:type",
            content: "villa",
          },
          // {
          // property: "og:url",
          // content: `https://www.gaia-travels.com ${}` category.get_old_absolute_url }}`,
          // },
          {
            property: "og:image",
            // HACK FOR BROKEN JSON GALLERIES CHECK LATER
            content: `https://www.gaia-travels.com${
              data.gaiaPage.property?.gallery
                ? data.gaiaPage.property?.gallery[0].url
                : ""
            }`,
          },
          // {
          //   property: "og:description",
          //   content: property.description_long,
          // },
          {
            property: "og:latitude",
            content: lat,
          },
          {
            property: "og:longitude",
            content: lon,
          },
          {
            property: "og:locality",
            content: property.municipality.province.name,
          },
          {
            property: "og:region",
            content: property.region.name,
          },
          {
            property: "og:country-name",
            content: "Italy",
          },
        ]}
      />
      {route}
    </>
  );
};

export default Property;
export const query = graphql`
  query PropertyQuery($id: String) {
    gaiaPage(id: { eq: $id }) {
      related_pages {
        # fields {
        #   first_picture {
        #     childImageSharp {
        #       fluid(maxWidth: 450, maxHeight: 350) {
        #         ...GatsbyImageSharpFluid
        #       }
        #     }
        #   }
        # }
        first_picture {
          url
          placeholder
        }
        property {
          marketing_name
          property_types
          starting_price
          occupancy
        }
        url
        name
      }
      has_discounts
      discounts
      url

      first_picture {
        url
        placeholder
      }

      # fields {
      #   confirmation_picture: first_picture {
      #     childImageSharp {
      #       fluid(maxWidth: 2560, fit: OUTSIDE) {
      #         ...GatsbyImageSharpFluid
      #       }
      #     }
      #   }
      #   first_picture {
      #     childImageSharp {
      #       fluid(maxWidth: 855, maxHeight: 570) {
      #         ...GatsbyImageSharpFluid
      #       }
      #     }
      #   }
      #   gallery {
      #     childImageSharp {
      #       fluid(maxWidth: 855, maxHeight: 570) {
      #         ...GatsbyImageSharpFluid
      #       }
      #       original {
      #         src
      #       }
      #     }
      #     publicURL
      #   }
      # }
      suites {
        id
        name
        price_min
      }
      gallery {
        url
        placeholder
      }
      property {
        id
        marketing_name
        integration_type
        property_types
        property_types_json {
          display_name
          key
        }
        starting_price
        pool {
          id
          width
          length
          open_from
          open_to
        }
        gps_coordinates
        prices {
          priceperiods {
            price
          }
        }
        municipality {
          name
          province {
            name
          }
        }
        region {
          name
        }
        country {
          name
        }
        description_long_html
        description_short_html
        generalfeatures {
          suitable_for_disabled
          child_friendly
          pets_allowed
          asphalted_road
          panoramic_position
          services_close
          private_parking
        }
        indoorfeatures {
          area_sqm
          bedrooms
          sleeping_places
          extra_beds
          bathrooms
          dish_washer
          washing_machine
          ac
          satelite_tv
          tv
          dvd
          indoor_jacuzzi
          oven
          freezer
          toaster
          microwave_oven
          am_coffee
          hair_dryer
          iron
          high_chair
          cradle
          baby_bed
        }
        outdoorfeatures {
          sunbeds_and_umbrellas
          outdoor_dining
          outdoor_furniture
          barbeque
          gazebo
          pergola
          terrace
          patio
          playing_area
          outdoor_jacuzzi
          mountain_bikes
          tennis
          table_tennis
          trekking
          fenced_garden
        }

        current_season {
          extra_payments
          optional_payments
          price_includes
          check_in_time
          check_out_time
          discounts_offers
          discounts_offers_type
          periods
          year
        }
      }
    }
  }
`;

class Availability {
  constructor(serverResult) {
    this.isLoading = false;
    this.suites = {};
    this.termsAndConditions = serverResult.terms_and_conditions || "";
    for (let key of Object.keys(serverResult)) {
      if (key === "terms_and_conditions") {
        continue;
      }
      this.suites[key] = serverResult[key].map(period => ({
        ...period,
        from_date: parseISO(period.from_date),
        to_date: parseISO(period.to_date),
      }));
    }
  }

  findPeriod(date, suite) {
    return this.suites[suite || "property"].find(period => {
      return isWithinInterval(date, {
        start: period.from_date,
        end: period.to_date,
      });
    });
  }

  allPeriodsOfRange(fromDate, toDate, suite) {
    const fromPeriodIndex = this.suites[suite || "property"].findIndex(
      period => {
        return isWithinInterval(fromDate, {
          start: period.from_date,
          end: period.to_date,
        });
      }
    );
    const toPeriodIndex = this.suites[suite || "property"].findIndex(period => {
      return isWithinInterval(toDate, {
        start: period.from_date,
        end: period.to_date,
      });
    });
    return this.suites[suite || "property"].slice(
      fromPeriodIndex,
      toPeriodIndex + 1
    );
  }

  calculatePrice(fromDate, toDate, suite) {
    const periods = this.allPeriodsOfRange(fromDate, toDate, suite);
    let sum = 0;
    let start = fromDate;
    for (const period of periods) {
      const nextEnd = min([toDate, period.to_date]);
      sum += differenceInCalendarDays(nextEnd, start) * (period.price / 7);
      start = nextEnd;
    }

    return sum.toFixed(0);
  }
}

// https://github.com/date-fns/date-fns/
function isWithinInterval(dirtyDate, dirtyInterval) {
  var interval = dirtyInterval || {};
  var time = toDate(dirtyDate).getTime();
  var startTime = toDate(interval.start).getTime();
  var endTime = toDate(interval.end).getTime();

  // Throw an exception if start date is after end date or if any date is `Invalid Date`
  if (!(startTime <= endTime)) {
    throw new RangeError("Invalid interval");
  }

  return time >= startTime && time < endTime;
}
