// PROPERTY FEATURES
/// GENERAL FEATURES - list of icons under heading
//
/// TOP INDOOR FEATURES - list of icons under description WITH icons
/// INDOOR FEATURES, OUTDOOR FEATURES - list of icons under description WITHOUT icons

import React from "react";
import { get } from "lodash";

import PropertyFeature from "./property-feature";

import {
  faArrowsAlt,
  faBabyCarriage,
  faBath,
  faBed,
  faDog,
  faDoorOpen,
  faEye,
  faGlassCheers,
  faParking,
  faRoad,
  faSwimmingPool,
  faUsers,
  faWheelchair,
  // faWifi,
  // faTv,
  // faSnowflake,
  // faMugHot,
  // faTimesCircle,
  // faBreadSlice,
  // faPaw,
  // faBaby,
  // faMountain,
  // faStore,
  // faUmbrellaBeach,
  // faUtensils,
  // faHiking,
  // faCoffee,
  // faHotTub,
  // faBicycle,
  // faTableTennis,
  // faTree,
} from "@fortawesome/free-solid-svg-icons";

const generalFeatures = [
  {
    key: "indoorfeatures.sleeping_places",
    icon: faUsers,
    label: property =>
      `${property.indoorfeatures.sleeping_places}${
        property.indoorfeatures.extra_beds
          ? " + " + property.indoorfeatures.extra_beds
          : ""
      } vierasta`,
  },
  {
    key: "indoorfeatures.area_sqm",
    icon: faArrowsAlt,
    label: property => (
      <>
        {property.indoorfeatures.area_sqm} m<sup>2</sup>
      </>
    ),
  },
  {
    key: "indoorfeatures.bedrooms",
    icon: faDoorOpen,
    label: property => `${property.indoorfeatures.bedrooms} makuuhuoneita`,
  },
  {
    key: "indoorfeatures.sleeping_places",
    icon: faBed,
    label: property =>
      `${property.indoorfeatures.sleeping_places} vuodepaikkoja`,
  },
  {
    key: "indoorfeatures.bathrooms",
    icon: faBath,
    label: property => `${property.indoorfeatures.bathrooms} kylpyhuoneita`,
  },
  {
    key: "pool.id",
    icon: faSwimmingPool,
    label: property =>
      `uima-allas (${property.pool.length}x${property.pool.width}m)`,
    // labelOff: "ei oma uima-allasta",
  },
];

const topIndoorFeatures = [
  {
    key: "generalfeatures.suitable_for_disabled",
    icon: faWheelchair,
    label: "Liikuntarajoitteisille",
  },
  {
    key: "generalfeatures.child_friendly",
    icon: faBabyCarriage,
    label: "Lapsiystävällinen",
  },
  {
    key: "generalfeatures.pets_allowed",
    icon: faDog,
    label: "Lemmikit sallittu",
    // labelOff: "Pets not allowed",
  },
  {
    key: "generalfeatures.asphalted_road",
    icon: faRoad,
    label: "Päällystetty tie",
  },
  {
    key: "generalfeatures.panoramic_position",
    icon: faEye,
    label: "Maisemapaikka",
  },
  {
    key: "generalfeatures.services_close",
    icon: faGlassCheers,
    label: "Palvelut lähellä",
  },
  {
    key: "generalfeatures.private_parking",
    icon: faParking,
    label: "Oma parkkipaikka",
  },
];

const indoorFeatures = [
  {
    key: "indoorfeatures.dish_washer",
    // icon: fasWasher,
    label: "Astianpesukone",
  },
  {
    key: "indoorfeatures.washing_machine",
    // icon: fasWasher,
    label: "Pesukone",
  },
  {
    key: "indoorfeatures.ac",
    // icon: fasAirconditioner,
    label: "Ilmastointi",
  },
  {
    key: "indoorfeatures.tv",
    // icon: faTv,
    label: "Tv",
  },
  {
    key: "indoorfeatures.satelite_tv",
    // icon: faTv,
    label: "SatTv",
  },
  {
    key: "indoorfeatures.dvd",
    // icon: ,
    label: "Dvd",
  },
  {
    key: "indoorfeatures.indoor_jacuzzi",
    // icon: faHotTub,
    label: "Jacuzzi sisällä",
  },
  {
    key: "indoorfeatures.oven",
    // icon: fasOven,
    label: "Uuni",
  },
  {
    key: "indoorfeatures.freezer",
    // icon: fasRefrigerator,
    label: "Pakastin",
  },
  {
    key: "indoorfeatures.toaster",
    // icon: ,
    label: "Paahdin",
  },
  {
    key: "indoorfeatures.microwave_oven",
    // icon: fasMicrowave,
    label: "Mikro",
  },
  {
    key: "indoorfeatures.am_coffee",
    // icon: faCoffee,
    label: "Kahvinkeitin",
  },
  {
    key: "indoorfeatures.hair_dryer",
    // icon: ,
    label: "Hiustenkuivain",
  },
  {
    key: "indoorfeatures.iron",
    // icon: ,
    label: "Silitysrauta",
  },
  {
    key: "indoorfeatures.high_chair",
    // icon: ,
    label: "Syöttötuoli",
  },
  {
    key: "indoorfeatures.baby_bed",
    // icon: ,
    label: "Lastensänky",
  },
  {
    key: "indoorfeatures.cradle",
    // icon: ,
    label: "Kehto",
  },
];

const outdoorFeatures = [
  {
    key: "outdoorfeatures.sunbeds_and_umbrellas",
    // icon: faUmbrellaBeach,
    label: "Aurinkotuoleja",
  },
  {
    key: "outdoorfeatures.outdoor_dining",
    // icon: ,
    label: "Ruokailutila ulkona",
  },
  {
    key: "outdoorfeatures.outdoor_furniture",
    // icon: ,
    label: "Pihakalusteet",
  },
  {
    key: "outdoorfeatures.barbeque",
    // icon: fasShishKebab,
    label: "Grillauspaikka",
  },
  {
    key: "outdoorfeatures.gazebo",
    // icon: ,
    label: "Gazebo",
  },
  {
    key: "outdoorfeatures.pergola",
    // icon: ,
    label: "Pergola",
  },
  {
    key: "outdoorfeatures.terrace",
    // icon: ,
    label: "Terassi",
  },
  {
    key: "outdoorfeatures.patio",
    // icon: ,
    label: "Patio",
  },
  {
    key: "outdoorfeatures.playing_area",
    // icon: fasKite,
    label: "Leikkialue",
  },
  {
    key: "outdoorfeatures.outdoor_jacuzzi",
    // icon: faHotTub,
    label: "Jacuzzi ulkona",
  },
  {
    key: "outdoorfeatures.mountain_bikes",
    // icon: faBicycle,
    label: "Polkupyöriä",
  },
  {
    key: "outdoorfeatures.tennis",
    // icon: fasTennisBall,
    label: "Tennis",
  },
  {
    key: "outdoorfeatures.table_tennis",
    // icon: faTableTennis,
    label: "Pöytätennis",
  },
  {
    key: "outdoorfeatures.trekking",
    // icon: faHiking,
    label: "Trekking",
  },
  {
    key: "outdoorfeatures.fenced_garden",
    // icon: ,
    label: "Aidattu piha",
  },
];

const PropertyFeatureList = ({ property, type, limit }) => {
  const detailsLeftColumn = [];
  const detailsRightColumn = [];
  let list;
  let textClassName;
  if (type === "generalFeatures") {
    list = generalFeatures;
  } else if (type === "topIndoorFeatures") {
    list = topIndoorFeatures;
    textClassName = "text-muted";
  } else {
    // type=moreFeatures
    list = indoorFeatures.concat(outdoorFeatures);
    textClassName = "text-muted";
  }

  for (const detail of list) {
    let detailElement;
    if (get(property, detail.key)) {
      detailElement = (
        <li key={detail.key} className="mb-2">
          <PropertyFeature
            textClassName={textClassName ? textClassName : ""}
            icon={detail.icon}
            label={
              typeof detail.label === "function"
                ? detail.label(property)
                : detail.label
            }
            size="sm"
          />
        </li>
      );
    } else if (detail.labelOff) {
      detailElement = (
        <li key={detail.key} className="mb-2">
          <PropertyFeature
            className={"text-primary"}
            icon={detail.icon}
            label={
              typeof detail.labelOff === "function"
                ? detail.labelOff(property)
                : detail.labelOff
            }
            size="sm"
            isOff
          />
        </li>
      );
    }

    if (detailElement) {
      if (detailsLeftColumn.length > detailsRightColumn.length) {
        detailsRightColumn.push(detailElement);
      } else {
        detailsLeftColumn.push(detailElement);
      }
    }
  }

  return (
    <>
      <div className="col-md-6">
        <ul className="list-unstyled text-primary m-0">{detailsLeftColumn}</ul>
      </div>
      <div className="col-md-6">
        <ul className="list-unstyled text-primary m-0">{detailsRightColumn}</ul>
      </div>
    </>
  );
};

export default PropertyFeatureList;
