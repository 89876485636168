/**
 * Property booking form for mobile
 **/

// Calendar states:
//
// + min_period
// rotation (checkin)
// rotation_departure (checkout)
// display price in tile
// calculate sums in range ?
//
//
//
//
//

import React, { useState, useRef } from "react";

import {
  GuestsSelect,
  ChildrenSelect,
} from "../property-page/property-booking-form-selects";

import useOutsideClick from "../../hooks/useOutsideClick";

import Select from "react-select";
import BookingCalendar from "./booking/booking-calendar";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";

const PropertyBookingForm = ({
  property,
  gaiaPage,
  mobileView,
  availability,
  onStartReservation,
}) => {
  const [bookingFormState, setBookingFormState] = useState({
    calendar: null,
    suite: null,
    guests: null,
    children: null,
  });

  const handleInputChange = (name, value) => {
    setBookingFormState({
      ...bookingFormState,
      [name]: value,
    });
  };

  let priceClasses = "text-muted";
  let calendarDoubleView = true;
  if (mobileView) {
    priceClasses += " text-center";
    calendarDoubleView = false;
  }

  let formattedDates = "";
  if (bookingFormState.calendar) {
    formattedDates = `${bookingFormState.calendar[0].toLocaleDateString(
      "fi-FI"
    )} - ${bookingFormState.calendar[1].toLocaleDateString("fi-FI")}`;
  }

  const [calendarExpanded, setCalendarExpanded] = useState(false);

  let calendar;
  const calendarRef = useRef();

  useOutsideClick(calendarRef, e => {
    if (calendarExpanded) {
      setCalendarExpanded(false);
    }
  });

  if (calendarExpanded && availability && !availability.isLoading) {
    calendar = (
      <div
        ref={calendarRef}
        className="date-picker-wrapper no-shortcuts inline-wrapper"
      >
        <BookingCalendar
          showDoubleView={calendarDoubleView}
          value={bookingFormState.calendar}
          onChange={value => {
            handleInputChange("calendar", value);
            setCalendarExpanded(false);
          }}
          availability={availability}
          suite={bookingFormState.suite?.value}
        />
      </div>
    );
  } else if (calendarExpanded && (!availability || availability.isLoading)) {
    calendar = (
      <div className="date-picker-wrapper no-shortcuts inline-wrapper">
        Loading...
      </div>
    );
  }

  let price;
  if (bookingFormState.calendar && availability && !availability.isLoading) {
    price = (
      <>
        <span className="text-primary h2 mr-2">
          €
          {availability.calculatePrice(
            bookingFormState.calendar[0],
            bookingFormState.calendar[1],
            bookingFormState.suite?.value
          )}
        </span>{" "}
        / vuokrahinta
      </>
    );
  } else {
    let startingPrice = property.starting_price;
    if (bookingFormState.suite) {
      startingPrice = gaiaPage.suites.find(
        suite => suite.id === bookingFormState.suite.value
      ).price_min;
    }
    price = (
      <>
        alk <span className="text-primary h2 mr-2">€{startingPrice}</span>/ vko
      </>
    );
  }

  let isFormEmpty =
    bookingFormState.calendar === null || bookingFormState.guests === null;
  // bookingFormState.children === null;

  let suiteSelect;
  if (gaiaPage.suites) {
    const suiteOptions = gaiaPage.suites.map(({ id, name, price_min }) => ({
      value: id,
      label: `${name} (alk €${price_min})`,
    }));
    suiteSelect = (
      <div className="from-group">
        <label htmlFor="suite" className="form-label">
          Huoneisto *
        </label>
        <Select
          name="suite"
          id="suite"
          placeholder="Huone"
          value={bookingFormState.suite}
          onChange={(value, event) => {
            handleInputChange(event.name, value);
          }}
          options={suiteOptions}
        />
      </div>
    );
  }

  return (
    <>
      <p className={priceClasses}>{price}</p>
      <hr className="my-4" />
      <form
        id="booking-form"
        method="get"
        action="#"
        autoComplete="off"
        className="form"
      >
        {suiteSelect}
        <div className="form-group">
          <label htmlFor="bookingDate" className="form-label">
            Loma-aika *
          </label>
          <div className="datepicker-container datepicker-container-right">
            <input
              type="text"
              name="bookingDate"
              id="bookingDate"
              placeholder="Päivämäärät"
              required="required"
              className={`form-control calendar-input ${
                gaiaPage.suites && !bookingFormState.suite ? "disabled" : ""
              }`}
              readOnly
              disabled={gaiaPage.suites && !bookingFormState.suite}
              onClick={() => {
                setCalendarExpanded(!calendarExpanded);
              }}
              value={formattedDates}
            />
            {calendar}
          </div>
        </div>
        <div className="form-group">
          <GuestsSelect
            className=""
            property={property}
            value={bookingFormState.guests}
            isDisabled={gaiaPage.suites && !bookingFormState.suite}
            onChange={(value, event) => {
              handleInputChange(event.name, value);
            }}
          />
          <ChildrenSelect
            // isDisabled
            className=""
            property={property}
            value={bookingFormState.children}
            isDisabled={gaiaPage.suites && !bookingFormState.suite}
            onChange={(value, event) => {
              handleInputChange(event.name, value);
            }}
          />
        </div>

        <div className="form-group">
          <button
            type="submit"
            className={`btn ${
              isFormEmpty ? "btn-outline-primary" : "btn-primary"
            } btn-block`}
            disabled={isFormEmpty}
            onClick={e => {
              e.preventDefault();
              onStartReservation(bookingFormState);
            }}
          >
            Aloita varaus
          </button>
        </div>
      </form>
      <p className="text-muted text-sm text-center">Ihastu Italiaan!</p>
    </>
  );
};
export default PropertyBookingForm;
